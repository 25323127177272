import React, { Component } from "react"
import { useStaticQuery, graphql } from "gatsby"
import { Link } from "gatsby"

const Links = () => {
  const data = useStaticQuery(graphql`
    query TopMenuQuery {
      site {
        siteMetadata {
          menuLinks {
            link
            name
          }
        }
      }
    }
  `)
  return (
    <>
      {data.site.siteMetadata.menuLinks.map(link => (
        <Link
          key={link.name}
          to={link.link}
          className="navbar-item"
          activeClassName="active"
        >
          {link.name}
        </Link>
      ))}
    </>
  )
}
class MainNav extends Component {
  constructor(props) {
    super(props)
    this.state = { active: false }
  }
  handleClick = () => {
    this.setState({ active: !this.state.active })
  }
  render() {
    return (
      <>
        <button
          className={`navbar-burger burger ${
            this.state.active ? "is-active" : ""
          }`}
          data-target="navMenu"
          aria-label="menu"
          aria-expanded="false"
          onClick={this.handleClick}
        >
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
        </button>
        <div
          className={`navbar-menu header-navbar-menu ${
            this.state.active ? "is-active" : ""
          }`}
          id="navMenu"
        >
          <div className="navbar-end">
            <Links />
          </div>
        </div>
      </>
    )
  }
}

export default MainNav
