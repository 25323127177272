import React from "react"
import Logo from "./logo"
import MainNav from "./menu"

const Header = () => (
  <nav
    className="navbar"
    role="navigation"
    aria-label="main navigation"
  >
    <div className="container">
      <Logo />
      <MainNav />
    </div>
  </nav>
)

export default Header
