import React from "react"
import ContactCta from "./contact"
import FooterNote from "./note"
import FooterNav from "./menu"
import PropTypes from "prop-types"

const Footer = () => (
  <footer className="footer">
    <div className="section">
      <div className="container">
        <ContactCta />
        <FooterNote />
      </div>
      <FooterNav />
    </div>
  </footer>
)

Footer.propTypes = {
  siteTitle: PropTypes.string,
}

Footer.defaultProps = {
  siteTitle: ``,
}

export default Footer
