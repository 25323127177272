import React from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import ImgLogo from "../../../images/sixpixels-logo.svg"

const Logo = () => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)
  return (
    <div className="navbar-brand">
      <Link
        to="/"
        aria-label={`${data.site.siteMetadata.title}, back to homepage`}
      >
        <figure className="image">
          <img src={ImgLogo} alt={`${data.site.siteMetadata.title} Logo`} />
        </figure>
        <span className="is-sr-only">{`${data.site.siteMetadata.title}`}</span>
      </Link>
    </div>
  )
}

export default Logo
