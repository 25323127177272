import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { Link } from "gatsby"

const FooterNav = () => {
  const data = useStaticQuery(graphql`
    query MenuQuery {
      site {
        siteMetadata {
          menuLinks {
            link
            name
          }
        }
      }
    }
  `)
  return (
    <nav
      className="navbar is-fixed-bottom is-hidden-desktop"
      role="navigation"
      aria-label="footer navigation"
    >
      <div className="container">
        <div className="navbar-menu footer-navbar-menu is-active" id="navFooterMenu">
          {data.site.siteMetadata.menuLinks.map(link => (
            <Link
              key={link.name}
              to={link.link}
              className="navbar-item"
              activeClassName="active"
            >
              <span className="icon"></span>
              {link.name}
            </Link>
          ))}
        </div>
      </div>
    </nav>
  )
}

export default FooterNav
