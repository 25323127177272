import React from "react"
import { Link } from "gatsby"

const LinkButton = props => (
  <Link data-sal="slide-up" to={props.url} className="button is-link">
    {props.text}
  </Link>
)

export default LinkButton
