import React from "react"
import CookieConsent from "react-cookie-consent"
import { Link } from "gatsby"

const Cookie = () => (
  <CookieConsent
    location="bottom"
    buttonText="De acord"
    declineButtonText="Decline"
    cookieName="gatsby-gdpr-google-analytics"
    disableStyles={true}
    buttonClasses="button"
  >
    {" "}
    Website-ul nostru utilizează module cookie pentru a monitoriza traficul cu
    scopul de a-ți oferi cea mai bună experiență online. Continuând navigarea pe
    acest website, iți exprimi acordul asupra folosirii cookie-urilor.{" "}
    <Link to="/privacy">Află mai mult</Link>.
  </CookieConsent>
)

export default Cookie
