/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import "../styles/style.scss"
import Header from "./header"
import Footer from "./footer"
import Cookie from "./cookieConsent"

const Layout = ({ children }) => (
  <>
    <Header />
    <main>{children}</main>
    <Footer />
    <Cookie />
  </>
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
