import React from "react"
import { Link, useStaticQuery, graphql } from "gatsby"

const FooterNote = () => {
  const data = useStaticQuery(graphql`
    query FooterQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)
  return (
    <div className="content footer-note">
      © {new Date().getFullYear()} {data.site.siteMetadata.title}.{" "}
      <Link to="/privacy">Politica de confidențialitate</Link>.
    </div>
  )
}

export default FooterNote
