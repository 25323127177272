import React from "react"
import LinkButton from "../../elements/button"

const ContactCta = () => (
  <div className="content contact-cta" data-sal="slide-up">
    <h2>Contact</h2>
    <h3>Te-am convins? Hai să ne cunoaștem</h3>
    <p>
      Fie că vrei să pui un proiect pe picioare sau cauți să transformi un
      business, găsim o soluție pentru tine.
    </p>
    <LinkButton url="/contact" text="Contactează-ne" />
  </div>
)

export default ContactCta
